import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Grid, ProgressBar, LoginContext } from '@ww/react-ui-components';
import * as userMutations from '/gql/mutations/user.mutations';
import { useMutation } from '@apollo/client';
import { useAutoRedirect } from '/hooks/useAutoRedirect';
import * as OPST from 'owasp-password-strength-test';
import PasswordField from '/components/common/PasswordField';

import { decodeJwt } from '@ww/js-core/lib/utils/jwt';
import IconAnnouncement from '/components/common/IconAnnouncement';
import { useNavigate } from 'react-router-dom';
import {
  StyledAnnouncementContainer,
  StyledContainerGrid,
  StyledPasswordsContainer,
  StyledTypography,
  SignInFabContainer,
  StyledFab,
  SetNewPasswordLoader,
} from './styled';
import PathContext from '/components/Login/PathContext';

export default function SetNewPassword() {
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [firstPassword, setFirstPassword] = useState<string>('');
  const [secondPassword, setSecondPassword] = useState<string>('');

  //    Todo - handle the general error screen
  const [, setGeneralPasswordError] = useState<string[]>([]);
  const [firstPasswordErrors, setFirstPasswordErrors] = useState<string[]>([]);
  const [secondPasswordErrors, setSecondPasswordErrors] = useState<string[]>([]);
  const [showFirstPassword, setShowFirstPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const history = useNavigate();
  const {
    state: { isOtp, prevUrl, appName },
    dispatch,
  } = useContext(LoginContext);
  const { prefix } = useContext(PathContext);
  useAutoRedirect();

  useEffect(() => {
    if (firstPassword) {
      const { errors: firstErrors = [] } = OPST.test(firstPassword);
      setFirstPasswordErrors(firstErrors);
    }

    if (secondPassword) {
      let additionalErrors: string[] = [];
      if (secondPassword !== firstPassword) {
        additionalErrors = additionalErrors.concat('The Passwords should match');
      }

      setSecondPasswordErrors(additionalErrors);
    }
  }, [firstPassword, secondPassword]);

  const [setFirstPasswordMutation, { data, loading }] = useMutation(
    userMutations.setFirstPassword,
    {
      onError: error => {
        /* eslint-disable-next-line */
        console.error(error.message);

        //  The reaction to this change isn't implemented - waiting for design
        setGeneralPasswordError([error.message]);
      },
    }
  );

  useEffect(() => {
    setSubmissionSuccess(data?.setFirstPassword?.status);
  }, [data?.setFirstPassword?.status]);

  useEffect(() => {
    if (!isOtp) {
      history(prefix ?? '/');
    }
  }, [isOtp]);

  const {
    state: { token },
  } = useContext(LoginContext);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    await setFirstPasswordMutation({
      variables: { password: firstPassword },
    });
  }

  const passwordFieldsDefinition = [
    {
      wwComponentName: 'otp.setFirstPassword',
      placeholder: 'New Password',
      value: firstPassword,
      showPassword: showFirstPassword,
      setErrorMessage: setFirstPasswordErrors,
      setPassword: setFirstPassword,
      setShowPassword: setShowFirstPassword,
      errors: firstPasswordErrors,
    },
    {
      wwComponentName: 'otp.setSecondPassword',
      placeholder: 'Confirm Password',
      value: secondPassword,
      showPassword: showSecondPassword,
      setErrorMessage: setSecondPasswordErrors,
      setPassword: setSecondPassword,
      setShowPassword: setShowSecondPassword,
      errors: secondPasswordErrors,
    },
  ];

  const { username = '' } = token ? decodeJwt(token) : { username: null };

  function continueToLogin() {
    history(`${prefix ?? ''}/?prevUrl=${encodeURIComponent(prevUrl ?? '')}&appName=${appName}`);

    dispatch({ type: 'UPDATE_OTP', isOtp: false });
    dispatch({ type: 'UPDATE_TOKEN', token: null });
  }

  if (submissionSuccess) {
    return (
      <StyledAnnouncementContainer container justify={'center'} alignItems={'center'}>
        <Grid item>
          <IconAnnouncement
            announcement={{ title: 'Your password has been set!', message: '' }}
            icon={'CheckCircleOutline'}
            wwComponentName={'setPassword.success'}
            actions={[{ buttonCallback: continueToLogin, buttonLabel: 'Continue to login' }]}
          />
        </Grid>
      </StyledAnnouncementContainer>
    );
  }

  return (
    <StyledContainerGrid direction={'column'} container justify={'center'} alignItems={'center'}>
      <Grid item>
        <StyledTypography variant={'h5'} fontWeight={200} align={'center'}>
          {username ? `Welcome ${username}, please set up your password` : ''}
        </StyledTypography>
      </Grid>
      <form style={{ display: 'contents' }} onSubmit={onSubmit}>
        <StyledPasswordsContainer item container direction={'column'} alignItems={'stretch'}>
          {passwordFieldsDefinition.map(
            ({
              wwComponentName,
              placeholder,
              value,
              showPassword,
              setErrorMessage,
              setPassword,
              setShowPassword,
              errors,
            }) => (
              <PasswordField
                key={wwComponentName}
                wwComponentName={wwComponentName}
                showPassword={showPassword}
                placeholder={placeholder}
                value={value}
                errors={errors}
                disabled={loading}
                onChange={value => {
                  setErrorMessage([]);
                  setPassword(value);
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            )
          )}
          <Grid item container justify={'center'}>
            <Grid item xs={6} sm={4} lg={3}>
              <SignInFabContainer>
                <StyledFab
                  size="large"
                  type="submit"
                  variant="extended"
                  color="secondary"
                  disabled={
                    !firstPassword ||
                    !secondPassword ||
                    loading ||
                    firstPasswordErrors.length > 0 ||
                    secondPasswordErrors.length > 0
                  }
                >
                  Continue
                  {loading && (
                    <SetNewPasswordLoader>
                      <ProgressBar size={24} color="secondary" />
                    </SetNewPasswordLoader>
                  )}
                </StyledFab>
              </SignInFabContainer>
            </Grid>
          </Grid>
        </StyledPasswordsContainer>
      </form>
    </StyledContainerGrid>
  );
}
