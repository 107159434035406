import { ActivityTypes } from 'types/common.types';

export type ActivityDetail = {
  icon: string,
  value: ActivityTypes,
  color: string,
  marker: string,
};

const AREA_VISIT_ACTIVITY = {
  icon: 'AreaVisit',
  value: ActivityTypes.IN_POLYGON,
  color: '#26bc9e',
  marker: 'AreaVisitMarker',
};

const TRANSMISSION_IN_AREA_ACTIVITY = {
  icon: 'Sensors',
  value: ActivityTypes.TRANSMISSION_IN_AREA,
  color: '#04658B',
  marker: 'TransmissionInAreaMarker',
};

const PORT_CALL_ACTIVITY = {
  icon: 'PortCall',
  value: ActivityTypes.PORT_CALL,
  color: '#0044ff',
  marker: 'PortCallMarker',
};

const DARK_ACTIVITY_ACTIVITY = {
  icon: 'DarkActivity',
  value: ActivityTypes.DARK_ACTIVITY,
  color: '#140F49',
  marker: 'DarkActivityMarker',
};

const FIRST_VISIT_ACTIVITY = {
  icon: 'FirstVisit',
  value: ActivityTypes.DEVIATION_FROM_PATTERN_FIRST_IN_POLYGON,
  color: '#ffc427',
  marker: 'FirstVisitMarker',
};

const ANCHORED_ACTIVITY = {
  icon: 'Anchored',
  value: ActivityTypes.LOW_SPEED_ANCHORED,
  color: '#4caf50',
  marker: 'AnchoredMarker',
};

const MEETING_ACTIVITY = {
  icon: 'ShipToShip',
  value: ActivityTypes.MEETING,
  color: '#29b6d2',
  marker: 'MeetingMarker',
};

const COURSE_DEVIATION_ACTIVITY = {
  label: 'generic.activityTypes.COURSE_DEVIATION',
  icon: 'SharpTurn',
  value: ActivityTypes.COURSE_DEVIATION,
  color: '#ef6215',
  marker: 'CourseDeviationMarker',
};

const DRIFTING_ACTIVITY = {
  icon: 'Drifting',
  value: ActivityTypes.LOW_SPEED_DRIFTING,
  color: '#7d3081',
  marker: 'DriftingMarker',
};

const SIGNAL_LOST_ACTIVITY = {
  icon: 'AisSignalLost',
  value: ActivityTypes.MISSING,
  color: '#dd1bc9',
  marker: 'SignalLostMarker',
};

const ID_MANIPULATION_ACTIVITY = {
  icon: 'IdentityTempering',
  value: ActivityTypes.ID_MANIPULATION,
  color: '#8e2034',
  marker: 'IdentityTemperingMarker',
};

const MMSI_CHANGE_ACTIVITY = {
  icon: 'MmsiChange',
  value: ActivityTypes.MMSI_CHANGE,
  color: '#fcb091',
  marker: 'MmsiChangeMarker',
};

const STANDING_ACTIVITY = {
  icon: 'SailingLessKn',
  value: ActivityTypes.STANDING,
  color: '#5d7515',
  marker: 'SailingLessKnMarker',
};

const ETA_CHANGE_ACTIVITY = {
  icon: 'Time',
  value: ActivityTypes.ETA_CHANGE,
  color: '#A3A8F9',
  marker: 'EtaChangeMarker',
};

const DESTINATION_CHANGE_ACTIVITY = {
  icon: 'PlaceOutlined',
  value: ActivityTypes.DESTINATION_CHANGE,
  color: '#85DB57',
  marker: 'DestinationChangeMarker',
};

const RESEARCH_OPERATION_ACTIVITY = {
  icon: 'Research',
  value: ActivityTypes.LOW_SPEED_RESEARCH,
  color: '#d28a88',
  marker: 'ResearchOperationMarker',
};

const FISHING_OPERATION_ACTIVITY = {
  icon: 'Fishing',
  value: ActivityTypes.LOW_SPEED_FISHING,
  color: '#d219c3',
  marker: 'FishingMarker',
};

const OFFSHORE_OPERARION_ACTIVITY = {
  icon: 'Offshore',
  value: ActivityTypes.LOW_SPEED_OFFSHORE_FACILITY,
  color: '#76c6d2',
  marker: 'OffshoreMarker',
};

const CASUALTY_ACTIVITY = {
  icon: 'Casualty',
  value: ActivityTypes.ACCIDENT,
  color: '#eb5c35',
  marker: 'CasualtyMarker',
};

export const ACTIVITIES_TYPES_DETAILS: ActivityDetail[] = [
  AREA_VISIT_ACTIVITY,
  PORT_CALL_ACTIVITY,
  DARK_ACTIVITY_ACTIVITY,
  FIRST_VISIT_ACTIVITY,
  ANCHORED_ACTIVITY,
  MEETING_ACTIVITY,
  COURSE_DEVIATION_ACTIVITY,
  DRIFTING_ACTIVITY,
  STANDING_ACTIVITY,
  SIGNAL_LOST_ACTIVITY,
  TRANSMISSION_IN_AREA_ACTIVITY,
  ID_MANIPULATION_ACTIVITY,
  MMSI_CHANGE_ACTIVITY,
  ETA_CHANGE_ACTIVITY,
  DESTINATION_CHANGE_ACTIVITY,
  RESEARCH_OPERATION_ACTIVITY,
  FISHING_OPERATION_ACTIVITY,
  OFFSHORE_OPERARION_ACTIVITY,
  CASUALTY_ACTIVITY,
];
