import React, { useContext, useEffect, useState } from 'react';
import { ISSUER_KEY, IssuerDetails } from '/utils/oauth';
import { useUrlQuery } from '/utils/url';
import * as issuerQueries from '/gql/queries/issuer.queries';
import { gql, useLazyQuery } from '@apollo/client';
import { Grid, WheelLoader } from '@ww/react-ui-components';

import IconAnnouncement, { Announcement } from '/components/common/IconAnnouncement';
import PathContext from '/components/Login/PathContext';
import { Route, Routes } from 'react-router-dom';
import { StyledContainerGrid, StyledGrid } from './styled';

import { OAuthLoginReturn } from './OAuthLoginReturn';

import { LoginWithIdentityProviderRedirect } from './LoginWithIdentityProviderRedirect';
import { OAUTH_ERRORS } from './constants';

const ALLOWED_SCOPES = ['mail', 'email', 'openid'];

const ADDITIONAL_SCOPES_FOR_USER_CREATION = ['profile'];

export type OAuthLoginProps = {
  prevUrl: string;
};

/*
OAuthLogin Component
read about it here: http://confluence.windward.com:8090/display/RD/Login-ui%3A+OAuth+Components+documentation
*/

function OAuthLogin() {
  const [error, setError] = useState<Announcement | null>();
  const [issuerDetails, setIssuerDetails] = useState<IssuerDetails | null>();
  const issuerUrl = useUrlQuery().get(ISSUER_KEY) ?? localStorage.getItem(ISSUER_KEY);
  const { prefix, basename } = useContext(PathContext);

  const [
    getIssuerCredentialsData,
    { loading: loadingIssuerCredentials, data: issuerCredentialsData },
  ] = useLazyQuery(
    gql`
      ${issuerQueries.getIssuer}
    `,
    {
      variables: {
        url: issuerUrl,
      },
      onError: error => {
        /* eslint-disable-next-line */
        console.error(error);
        setError(OAUTH_ERRORS.WRONG_ISSUER);
      },
    }
  );

  useEffect(() => {
    try {
      if (!issuerCredentialsData) {
        return;
      }

      if (!issuerCredentialsData.issuer) {
        throw new Error('No such identity provider registered');
      }

      const allowedScopes = [...ALLOWED_SCOPES];

      if (issuerCredentialsData.issuer.createUserIfNotExists) {
        allowedScopes.push(...ADDITIONAL_SCOPES_FOR_USER_CREATION);
      }

      const {
        openidConfiguration: {
          authorization_endpoint: authorizationURL,
          token_endpoint: tokenURL,
          scopes_supported: scope,
        },
        clientId,
        clientSecret,
        url,
      } = issuerCredentialsData.issuer;

      const newIssuerDetails: IssuerDetails = {
        issuer: url,
        clientID: clientId,
        clientSecret,
        authorizationURL,
        tokenURL,
        callbackURL: `${window.location.origin}${basename ?? ''}${prefix ?? ''}/auth/oidc/return${
          url === 'https://sso-dev.shell.com' ? '/' : ''
        }`, // Todo: remove once integration done
        scope: scope.filter((scopeName: string) => allowedScopes.includes(scopeName)).join(' '),
      };

      // Todo: remove me once integration done
      if (url === 'https://accounts.google.com/') {
        newIssuerDetails.clientSecret = 'CA-SU4vyvkz6YEqt3nr9FnYl';
      }

      setIssuerDetails(newIssuerDetails);
      localStorage.setItem(ISSUER_KEY, url);
      setError(null);
    } catch (exception) {
      /* eslint-disable-next-line */
      console.error(
        error,
        'OAUTH_ERRORS.WRONG_ISSUER',
        'error while Getting issuer credentials data'
      );
      setError(OAUTH_ERRORS.WRONG_ISSUER);
    }
  }, [issuerCredentialsData]);

  useEffect(() => {
    if (!issuerUrl && !error) {
      setError(OAUTH_ERRORS.WRONG_ISSUER);
    }

    if (!issuerCredentialsData && !loadingIssuerCredentials && issuerUrl) {
      getIssuerCredentialsData();
    }
  }, [issuerUrl]);

  return (
    <StyledContainerGrid>
      <StyledGrid>
        <Grid container spacing={3} wrap={'nowrap'} direction={'column'}>
          <Grid item xs={12}>
            {error && (
              <IconAnnouncement
                icon={'ErrorOutline'}
                announcement={error}
                wwComponentName={'OAuthLogin.error'}
              />
            )}
            {loadingIssuerCredentials && (
              <Grid item>
                <WheelLoader
                  size={'large'}
                  color={'inherit'}
                  wwComponentName={'OAuthLogin.loadingIssuerDetails'}
                />
              </Grid>
            )}
            {issuerDetails && (
              <Routes>
                <Route path="return" element={<OAuthLoginReturn issuerDetails={issuerDetails} />} />
                <Route
                  index
                  element={<LoginWithIdentityProviderRedirect issuerDetails={issuerDetails} />}
                />
              </Routes>
            )}
          </Grid>
        </Grid>
      </StyledGrid>
    </StyledContainerGrid>
  );
}

export default OAuthLogin;
