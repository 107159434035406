import styled from '@emotion/styled';
import { Grid } from '@ww/react-ui-components';

export const StyledContainerGrid = styled(Grid)<any>`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledGrid = styled('div')<any>`
  min-height: 344px;
  position: relative;
  text-align: center;
`;
