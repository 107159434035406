import { useUrlQuery } from '/utils/url';
import { useAsync } from 'react-use';
import { getTokenFromIdentityProvider } from '/utils/oauth';

import { LoginWithIDToken } from './LoginWithIDToken';
import { WheelLoader } from '@ww/react-ui-components';
import React, { useState } from 'react';
import { OAUTH_ERRORS } from './constants';
import IconAnnouncement, { Announcement } from '/components/common/IconAnnouncement';

type OAuthReturnProps = {
  issuerDetails: {
    issuer: string;
    clientID: string;
    clientSecret: string;
    authorizationURL: string;
    tokenURL: string;
    callbackURL: string;
    scope: string;
  };
};
const CODE_KEY_PARAM = 'code';
export function OAuthLoginReturn({ issuerDetails }: OAuthReturnProps) {
  const generatedCode = useUrlQuery().get(CODE_KEY_PARAM);
  const [error, setError] = useState<Announcement | null>(null);
  const { loading: loadingIdToken, value: idToken } = useAsync(async () => {
    if (!generatedCode || !issuerDetails || idToken) {
      return;
    }

    try {
      const response = await getTokenFromIdentityProvider(generatedCode, issuerDetails);

      if (!response?.data?.id_token) {
        throw new Error('missing id_token from identity provider.');
      }

      setError(null);

      return response?.data?.id_token;
    } catch (exception) {
      setError(OAUTH_ERRORS.NO_ID_TOKEN);
    }
  }, [issuerDetails]);

  return (
    <>
      {error && (
        <IconAnnouncement
          announcement={error}
          icon={'ErrorOutline'}
          wwComponentName="OAuthLoginReturn.error"
        />
      )}
      {idToken && <LoginWithIDToken idToken={idToken} />}
      {loadingIdToken && (
        <WheelLoader
          size={'large'}
          color={'inherit'}
          wwComponentName={'OAuthLoginReturn.loadingIdToken'}
        />
      )}
    </>
  );
}
