import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ExternalLink, Grid, Typography } from '@ww/react-ui-components';
import config from '/configuration';
import { Apps } from '@ww/js-core/lib/constants/applications';
import { LINK_TEXT, TYPOGRAPHY_TEXT } from '/constants/insightLabLink';

const StyledLink = styled(ExternalLink)`
  color: #3180ff;
  padding: 0;
  margin: 0;
`;

const StyledGrid = styled(Grid)<any>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 8px;
  margin-top: 30px;
  gap: 8px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
`;

export default function InsightLabLink({ appName }: { appName: String }) {
  const [isPlayground, setIsPlayground] = useState(false);

  useEffect(() => {
    if (appName === Apps.PLAYGROUND) {
      setIsPlayground(true);
    }
  }, [appName]);

  return (
    <>
      {isPlayground ? (
        <Grid item container justify={'center'}>
          <StyledGrid item>
            <Typography color="textSecondary" variant="body2">
              {TYPOGRAPHY_TEXT}
            </Typography>
            <StyledLink href={config.insightLabLink}>{LINK_TEXT}</StyledLink>
          </StyledGrid>
        </Grid>
      ) : null}
    </>
  );
}
