import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { get } from 'lodash';
import * as userMutations from '/gql/mutations/user.mutations';
import { useAutoRedirect } from '/hooks/useAutoRedirect';
import { useUrlQuery } from '/utils/url';

import { WheelLoader, LoginContext } from '@ww/react-ui-components';
import IconAnnouncement, { Announcement } from '/components/common/IconAnnouncement';
import { OAUTH_ERRORS } from './constants';
import PathContext from '/components/Login/PathContext';

const graphQlErrorCodes = {
  LOGIN_FREQUENCY_LIMIT_ERROR: 'LOGIN_FREQUENCY_LIMIT_ERROR',
};

export type LoginWithIDTokenProps = { idToken: string };

export function LoginWithIDToken({ idToken }: LoginWithIDTokenProps) {
  const [error, setError] = useState<Announcement | null>(null);
  const { useRefreshToken, authService } = useContext(PathContext);
  const {
    state: { appName },
  } = useContext(LoginContext);

  useAutoRedirect();
  const [appLogin, { data: loginResponse, loading: loadingLoginResponse }] = useMutation(
    userMutations.loginWithOAuthToken,
    {
      variables: {
        input: {
          oauthToken: idToken,
          application: appName,
          useRefreshToken,
        },
        useTemporaryCode: !authService,
      },
      onError: error => {
        if (
          get(error, 'graphQLErrors[0].extensions.code') ===
          graphQlErrorCodes.LOGIN_FREQUENCY_LIMIT_ERROR
        ) {
          setError(OAUTH_ERRORS.LOGIN_FREQUENCY_LIMIT);
        } else {
          setError(OAUTH_ERRORS.USER_NOT_FOUND);
        }
      },
    }
  );
  const { state: loginState, dispatch } = useContext(LoginContext);
  const [prevUrl] = useState<string | null>(
    JSON.parse(decodeURIComponent(useUrlQuery().get('state') ?? '{}'))?.prevUrl
  );

  useEffect(() => {
    if (loginState.prevUrl !== prevUrl) {
      dispatch({ type: 'UPDATE_PREV_URL', prevUrl });
    }
  }, [prevUrl]);

  useEffect(() => {
    if (!loginResponse) {
      return;
    }

    if (loginState.code !== loginResponse.appLoginOAuthToken.code) {
      dispatch({
        type: 'UPDATE_CODE',
        code: loginResponse.appLoginOAuthToken.code,
      });
    }

    const { accessToken, refreshToken } = loginResponse.appLoginOAuthToken.tokens ?? {};
    const userPreferences = loginResponse.appLoginOAuthToken.userPreferences ?? {};
    const userData = loginResponse.appLoginOAuthToken.user ?? {};

    if (loginState.token !== accessToken) {
      dispatch({ type: 'UPDATE_TOKEN', token: accessToken });
      dispatch({ type: 'UPDATE_USER_PREFERENCES', userPreferences });
      dispatch({ type: 'UPDATE_USER_DATA', userData });
    }

    if (loginState.refreshToken !== refreshToken) {
      dispatch({ type: 'UPDATE_REFRESH_TOKEN', refreshToken });
    }
  }, [loginResponse]);

  useEffect(() => {
    appLogin();
  }, [idToken]);

  return (
    <div>
      {error && (
        <IconAnnouncement
          icon={'ErrorOutline'}
          announcement={error}
          wwComponentName={'LoginWithIDToken.error'}
        />
      )}
      {loadingLoginResponse && (
        <WheelLoader
          size={'large'}
          color={'inherit'}
          wwComponentName={'LoginWithIDToken.loadingLoginResponse'}
        />
      )}
      {loginResponse?.appLoginOAuthToken?.code && (
        <WheelLoader
          size={'large'}
          color={'inherit'}
          wwComponentName={'LoginWithIDToken.success'}
        />
      )}
    </div>
  );
}
