import { post } from '@ww/js-core/lib/utils/http';

import { getChallenge, generateVerifier } from '@ww/js-core/lib/utils/crypto';

export const VERIFIER_KEY = 'oauth_verifier';
export const ISSUER_KEY = 'loginserver';

export type IssuerDetails = {
  issuer: string;
  clientID: string;
  clientSecret: string;
  authorizationURL: string;
  tokenURL: string;
  callbackURL: string;
  scope: string;
};

export async function getTokenFromIdentityProvider(code: string, providerConfig: IssuerDetails) {
  if (!providerConfig) {
    return null;
  }

  const { clientID, clientSecret, callbackURL, tokenURL, issuer } = providerConfig;
  const verifier = localStorage.getItem(VERIFIER_KEY) ?? '';

  // Todo: remove once integration done
  if (issuer === 'https://accounts.google.com/') {
    return post(tokenURL, {
      grant_type: 'authorization_code',
      client_id: clientID,
      code_verifier: verifier,
      code,
      redirect_uri: callbackURL,
      client_secret: clientSecret,
    });
  }

  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', clientID);
  params.append('code_verifier', verifier);
  params.append('code', code);
  params.append('redirect_uri', callbackURL);

  return post(tokenURL, params);
}

export function generateVerifierAndGetChallenge() {
  const verifier = generateVerifier();
  localStorage.setItem(VERIFIER_KEY, verifier);

  return getChallenge(verifier);
}

export function getRedirectUrl(providerConfig: IssuerDetails, challenge: string, state: object) {
  if (!providerConfig) {
    return false;
  }

  const { issuer, authorizationURL, clientID, callbackURL, scope } = providerConfig;

  return (
    `${authorizationURL}${
      issuer === 'https://accounts.google.com' ? '/authorize?' : '?' // Todo: remove once integration done
    }response_type=code&code_challenge=${challenge}&code_challenge_method=S256&` +
    `client_id=${clientID}&redirect_uri=${callbackURL}&scope=${scope}&state=${JSON.stringify(
      state
    )}`
  );
}
