import React, { useMemo } from 'react';
import AuthService from '@ww/react-utils/lib/services/auth.service';
import { LoginRoutes } from './Login.helper';
import { DEFAULT_LOGIN_PREFIX } from './constants';
import PathContext from './PathContext';
import { Navigate, useHref } from 'react-router-dom';
import { Global } from '@emotion/react';

/* @ts-ignore */
import Background from '/loginBackground.jpg';

export default function LoginComponent({
  prefix = DEFAULT_LOGIN_PREFIX,
  tokenKey,
  redirectUrl = '/',
  useRefreshToken = false,
  authService,
}: {
  prefix?: string;
  tokenKey?: string;
  redirectUrl?: string;
  useRefreshToken?: boolean;
  authService?: AuthService;
}) {
  const basename = useHref('/');
  const providerValue = useMemo(() => {
    const prefixValue = prefix === '/' ? '' : prefix;
    const baseNameValue = basename === '/' ? '' : basename;

    return {
      prefix: prefixValue,
      basename: baseNameValue,
      useRefreshToken,
      authService,
    };
  }, [prefix, basename, useRefreshToken, authService]);

  const tokenValue = useMemo(() => (tokenKey ? localStorage.getItem(tokenKey) : null), [tokenKey]);

  return (
    <>
      {tokenValue ? <Navigate to={redirectUrl} replace /> : null}
      <Global
        styles={{
          body: {
            background: `url(${Background})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'inherit',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
          },
        }}
      />
      <PathContext.Provider value={providerValue}>
        <LoginRoutes />
      </PathContext.Provider>
    </>
  );
}
