import { useLocation } from 'react-router-dom';

import { currentEnv } from '/configuration';

export function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

const WNWD_DOMAIN_REGEX = /\.wnwd\.com$/;
const S_NEPTUNE_DOMAIN_REGEX = /\.s-neptune\.com$/;
const OTS_DOMAIN_REGEX = /\.ots\.ai$/;
const RMOR_DOMAIN_REGEX = /\.rmor-maritime\.com$/;

const WINDWARD_DOMAIN_REGEX = /\.windward\.com$/;
const LOCALHOST_REGEX = /^(localhost|127\.0\.0\.1):/;

export function isPrevUrlValid(prevUrl) {
  if (!prevUrl) {
    return;
  }

  const prevUrlOrigin = new URL(prevUrl);
  const prevUrlHost = prevUrlOrigin.host;
  const wnwdDomain = WNWD_DOMAIN_REGEX.test(prevUrlHost);

  if (['qa', 'development', 'local'].includes(currentEnv)) {
    const windwardDomain = WINDWARD_DOMAIN_REGEX.test(prevUrlHost);
    const localhostDomain = LOCALHOST_REGEX.test(prevUrlHost);

    return windwardDomain || wnwdDomain || localhostDomain;
  }

  if (currentEnv === 'productionCadilac') {
    return S_NEPTUNE_DOMAIN_REGEX.test(prevUrlHost);
  }

  if (currentEnv === 'productionOceanTech') {
    return OTS_DOMAIN_REGEX.test(prevUrlHost);
  }

  if (currentEnv === 'productionRMOR') {
    return RMOR_DOMAIN_REGEX.test(prevUrlHost);
  }

  return wnwdDomain;
}
