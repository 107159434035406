import { merge } from 'lodash';
import config from '/config';
import all from './env/all';
import production from './env/production';
import productionOceanTech from './env/productionOceanTech';
import productionCadilac from './env/productionCadilac';
import productionRMOR from '/configuration/env/productionRMOR';

const DEFAULT_CONFIG = 'development';

let currentEnvironment = config.REACT_APP_DEPLOY_ENV ?? DEFAULT_CONFIG;

function setCurrentEnvironment() {
  const { host } = window.location;

  if (host.endsWith(productionOceanTech.domainName)) {
    currentEnvironment = 'productionOceanTech';

    return;
  }

  if (host.endsWith(productionCadilac.domainName)) {
    currentEnvironment = 'productionCadilac';
  }

  if (host.endsWith(productionRMOR.domainName)) {
    currentEnvironment = 'productionRMOR';
  }
}

setCurrentEnvironment();

const environments = {
  production,
  productionOceanTech,
  productionCadilac,
  productionRMOR,
};

const currentEnv = currentEnvironment;
export { currentEnv };
export default merge({}, all, environments[currentEnv]);
