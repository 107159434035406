import mixpanel from 'mixpanel-browser';
import isString from 'lodash/isString';

import { logger } from 'utils/logger';

export class AnalyticsService {
  __initialized = false;

  enabled = true;

  constructor(config) {
    this.enrichParams = config.enrichParams || (e => e);
    this.send = config.send || ((name, params) => mixpanel.track(name, params));
  }

  isInitialized() {
    return this.__initialized;
  }

  setPeople(props) {
    if (this.canSend) {
      mixpanel.people.set(props);
    }
  }

  timeEvent(name) {
    if (this.canSend) {
      mixpanel.time_event(name);
    }
  }

  get canSend() {
    return this.enabled && this.__initialized;
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  init(token, options) {
    if (token) {
      mixpanel.init(token, options);
      this.__initialized = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setConfig(configToExtend) {
    if (configToExtend) {
      return mixpanel.set_config(configToExtend);
    }
  }

  identify(id) {
    if (this.canSend) {
      mixpanel.identify(id);
    }
  }

  alias(id) {
    if (this.canSend) {
      mixpanel.alias(id);
    }
  }

  register(props) {
    if (this.canSend) {
      mixpanel.register(props);
    }
  }

  track(event, params = {}, sendToIntercom = false) {
    let action;
    let description;

    if (isString(event)) {
      action = event;
      description = '';
    } else {
      ({ action, description } = event);
    }

    if (this.canSend) {
      const enrichedParams = this.enrichParams(params);
      logger.log('%c[analytics]%c %s', 'color:red', 'color:green', action, enrichedParams);
      this.send(action, {
        ...enrichedParams,
        description,
      });
    }

    if (sendToIntercom && this.enabled && 'Intercom' in window) {
      window.Intercom('trackEvent', action, params);
    }
  }
}

export default function getConfig(config) {
  return new AnalyticsService(config);
}
