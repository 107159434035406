import jwkToPem from 'jwk-to-pem';
import jwt from 'jsonwebtoken';
import axios from 'axios';

export const verify = (accessToken, jwks) => {
  try {
    const certifiedKid = jwt.decode(accessToken, { complete: true }).header.kid;
    const jwk = jwks.keys.find(({ kid }) => kid === certifiedKid);
    const pem = jwkToPem(jwk);
    return jwt.verify(accessToken, pem, { algorithms: ['RS256'] });
  } catch (e) {
    throw new Error('invalid token');
  }
};

export async function getJwksFromIss(iss) {
  const { jwks_uri } = await getOpenidConfigurationFromIss(iss);
  const { data: jwks } = await axios.get(jwks_uri);
  return jwks;
}

export async function getOpenidConfigurationFromIss(iss) {
  const suffix = '.well-known/openid-configuration';
  const { data } = await axios.get(new URL(suffix, iss).toString());
  return data;
}

export const decodeJwt = accessToken => {
  return jwt.decode(accessToken);
};

export const signJwt = (userObj, secret, options) => {
  return jwt.sign(userObj, secret, options);
};
