import styled from '@emotion/styled';
import { Grid } from '@ww/react-ui-components';

export const AnimatedContainer = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 25vh;

  .fadeIn {
    opacity: 0;
    animation: fadeIn ease 1;
    animation-delay: 500ms;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      height: 80%;
    }
    to {
      opacity: 1;
      height: 100%;
    }
  }
`;

export const StyledContainerGrid = styled(Grid)<any>`
  height: 100%;
  width: 40%;
  overflow: hidden;
  color: white;
  margin: 0;

  @media only screen and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (max-width: 1000px) {
    width: 60%;
  }
  @media only screen and (max-width: 1000px) {
    width: 90%;
  }
`;

export const StyledLoginContainer = styled('div')`
  height: 100%;
  overflow: hidden;
  color: white;
`;
