import config from '/configuration';

import createAnalyticsService from '@ww/react-utils/lib/utils/AnalyticsService';
import { useContext } from 'react';

import { decodeJwt } from '@ww/js-core/lib/utils/jwt';
import { LoginContext } from '@ww/react-ui-components';

import { memoize } from 'lodash';

const token = config?.analytics.mixpanelToken;

const analyticsService = createAnalyticsService({});

if (
  analyticsService &&
  !analyticsService.__initialized &&
  token &&
  process.env.NODE_ENV !== 'test'
) {
  analyticsService.init(token);

  /* eslint-disable-next-line */
  console.log('[analytics] successfully initialized');
}

const getAnalyticsService = memoize(userToken => {
  if (!userToken || process.env.NODE_ENV === 'test') {
    return;
  }

  const {
    username,
    email,
    organization: { name: organizationName, isWindwardOrganization },
  } = decodeJwt(userToken);

  if (username && analyticsService.__initialized) {
    analyticsService.identify(username);
    analyticsService.setPeople({
      $email: email,
      $name: username,
    });
    analyticsService.register({
      Trial: null,
      'Windward Org': isWindwardOrganization,
      organization: organizationName,
    });

    /* eslint-disable-next-line */
    console.log('[analytics] settings: ', {
      username,
      email,
      organizationName,
      isWindwardOrganization,
    });
  }

  return analyticsService;
});

export function useAnalytics() {
  const {
    state: { token: userToken },
  } = useContext(LoginContext);

  return getAnalyticsService(userToken);
}
