import crypto from 'crypto';

export function generateVerifier() {
  return base64URLEncode(crypto.randomBytes(32));
}
export function getChallenge(verifier) {
  return base64URLEncode(sha256(verifier));
}

function base64URLEncode(str: any) {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer: string) {
  return crypto
    .createHash('sha256')
    .update(buffer)
    .digest();
}

export function getMd5Hash(str) {
  return crypto
    .createHash('md5')
    .update(str)
    .digest('hex');
}
