export default {
  api: {
    url: 'https://ww-gateway-qa.wnwd.com',
  },
  logoIcon: 'WindwardLogoWithTagline',
  analytics: {
    mixpanelToken: 'e250815a400ac3d5fca666abe8792550',
  },
  insightLabLink: 'https://windward.ai/api-hub/windward-api-insights-lab/#',
};
