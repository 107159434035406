import { Icon, IconButton } from '@ww/react-ui-components';
import React, { Dispatch } from 'react';

export default function InputEndIcon({
  onClick,
  showPassword,
}: {
  onClick: Dispatch<any>;
  showPassword: boolean;
}) {
  return (
    <IconButton onClick={() => onClick(!showPassword)}>
      {showPassword ? (
        <Icon fontSize={'small'} name={'Visibility'} wwComponentName={'VisibilityOnIcon'} />
      ) : (
        <Icon fontSize={'small'} name={'VisibilityOff'} wwComponentName={'VisibilityOffIcon'} />
      )}
    </IconButton>
  );
}
