import ExtendableError from 'utils/ExtendableError';
import AxiosError from 'exceptions/AxiosError';

export default class AxiosGraphqlError extends ExtendableError {
  constructor(error) {
    const { errors } = error.response?.data ?? {};
    let errorMsg = `Error while making request to GQL`;

    if (Array.isArray(errors)) {
      const distinctErrors = new Set(errors.map(e => e.message));
      errorMsg += ` errors=[${[...distinctErrors.values()]}]`;
    }

    super(errorMsg);

    const causeError = new AxiosError(error);
    causeError.code = error.code;

    this.cause = causeError;
  }
}
