import type { IconName } from '@ww/react-ui-components';
import React, { ReactNode } from 'react';
import { Fab, Grid, Icon, Typography, ProgressBar } from '@ww/react-ui-components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type IconAnnouncementProps = {
  announcement: Announcement;
  actions?: Array<AnnouncementAction>;
  icon: IconName;
  wwComponentName?: string;
};

export type AnnouncementAction = {
  buttonLabel: ReactNode | string;
  buttonCallback: () => void;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
};

export type Announcement = {
  title: ReactNode | string;
  message?: ReactNode | string;
};

const StyledFab = styled(Fab)`
  ${({ color, disabled }) => {
    const isPrimary = color === 'primary';

    return css`
      border: ${isPrimary ? '1px solid #3180ff' : undefined};
      box-sizing: ${isPrimary ? 'content-box' : undefined};
      color: ${isPrimary ? '#3180ff' : undefined};
      background-color: ${disabled ? '#d2dae1 !important' : undefined};
      &:hover {
        background-color: ${isPrimary ? 'rgb(250, 250, 250, 0.1)' : undefined};
      }
    `;
  }}
`;

const Loader = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function IconAnnouncement({ announcement, wwComponentName, icon, actions }: IconAnnouncementProps) {
  const { title, message = '' } = announcement;

  return (
    <Grid
      container
      spacing={4}
      wrap={'nowrap'}
      direction={'column'}
      justify={'center'}
      alignItems={'center'}
    >
      <Grid item>
        <Icon wwComponentName={wwComponentName} name={icon} fontSize={'large'} />
      </Grid>
      <Grid item>
        <Typography variant="h6" fontWeight={500}>
          {title}
        </Typography>
      </Grid>
      {message && (
        <Grid item>
          <Typography align="center" variant="subtitle1">
            {message}
          </Typography>
        </Grid>
      )}
      <Grid container item justify="center" spacing={4}>
        {actions?.map(
          ({ buttonCallback, buttonLabel, color = 'secondary', loading, disabled }) =>
            buttonCallback &&
            buttonLabel && (
              <Grid item key={String(buttonLabel)}>
                <StyledFab
                  variant="extended"
                  onClick={buttonCallback}
                  disabled={disabled}
                  color={color}
                  size="small"
                  component="span"
                >
                  {buttonLabel}
                  {loading && (
                    <Loader>
                      <ProgressBar size={24} color="secondary" />
                    </Loader>
                  )}
                </StyledFab>
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );
}

export default IconAnnouncement;
