// @flow
export type ObjectId = string;

export type { DateTimeRange, Blip } from '@ww/gql-schema-types';

export const ActivityTypes = {
  IN_POLYGON: 'IN_POLYGON',
  TRANSMISSION_IN_AREA: 'TRANSMISSION_IN_AREA',
  PORT_CALL: 'PORT_CALL',
  DARK_ACTIVITY: 'DARK_ACTIVITY',
  MMSI_CHANGE: 'MMSI_CHANGE',
  DEVIATION_FROM_PATTERN_FIRST_IN_POLYGON: 'DEVIATION_FROM_PATTERN_FIRST_IN_POLYGON',
  COURSE_DEVIATION: 'COURSE_DEVIATION',
  MEETING: 'MEETING',
  LOW_SPEED_ANCHORED: 'LOW_SPEED_ANCHORED',
  LOW_SPEED_DRIFTING: 'LOW_SPEED_DRIFTING',
  LOW_SPEED_FISHING: 'LOW_SPEED_FISHING',
  LOW_SPEED_RESEARCH: 'LOW_SPEED_RESEARCH',
  LOW_SPEED_IUU: 'LOW_SPEED_IUU',
  LOW_SPEED_OFFSHORE_FACILITY: 'LOW_SPEED_OFFSHORE_FACILITY',
  ID_MANIPULATION: 'ID_MANIPULATION',
  MISSING: 'MISSING',
  STANDING: 'STANDING',
  ACCIDENT: 'ACCIDENT',
  ETA_CHANGE: 'ETA_CHANGE',
  DESTINATION_CHANGE: 'DESTINATION_CHANGE',

  // IUU groups
  MEETING_IUU: 'MEETING_IUU',
  DARK_ACTIVITY_IUU: 'DARK_ACTIVITY_IUU',

  // MAV_SCS groups
  MEETING_MAV_SCS: 'MEETING_MAV_SCS',
  DARK_ACTIVITY_MAV_SCS: 'DARK_ACTIVITY_MAV_SCS',
  IN_POLYGON_MAV_SCS: 'IN_POLYGON_MAV_SCS',
};

export type ActivityType = $Keys<typeof ActivityTypes>;
