import { Navigate, useLocation } from 'react-router-dom';
import { Grid, GridProps, WheelLoader, LoginContext } from '@ww/react-ui-components';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useUrlQuery } from '/utils/url';
import { ISSUER_KEY } from '/utils/oauth';
import Header from './Header';
import {
  StyledLoginContainer,
  AnimatedContainer,
  StyledContainerGrid,
} from './LoginRouteWrapper.styled';
import PathContext from './PathContext';

function LoginRouteWrapper({
  children,
  spacing,
}: {
  children: ReactNode;
  spacing?: GridProps['spacing'];
}) {
  const { pathname } = useLocation();

  const prevUrlFromQuery = decodeURIComponent(useUrlQuery().get('prevUrl') ?? '');
  const {
    state: { prevUrl: prevUrlFromContext },
    dispatch,
  } = useContext(LoginContext);
  const { prefix, basename } = useContext(PathContext);
  const [prevUrl] = useState<string | null>(prevUrlFromQuery ?? prevUrlFromContext ?? null);

  const loginServer = useUrlQuery().get(ISSUER_KEY) ?? localStorage.getItem(ISSUER_KEY);

  useEffect(() => {
    if (prevUrl && prevUrlFromContext !== prevUrl) {
      dispatch({ type: 'UPDATE_PREV_URL', prevUrl });
    } else if (!prevUrl && !prevUrlFromContext && prefix !== '') {
      const currentUrl = new URL(window.location.toString());
      currentUrl.searchParams.append('prevUrl', `${window.location.origin}${basename ?? ''}`);
      window.location.replace(currentUrl);
    }
  }, [prevUrl, basename]);

  return (
    <StyledLoginContainer>
      {prevUrl && loginServer && !pathname.includes('/auth/oidc') && (
        <>
          <WheelLoader wwComponentName={'LoginContainer.redirecting'} />
          <Navigate to={`${prefix}/auth/oidc?${ISSUER_KEY}=${loginServer}`} />
        </>
      )}
      <AnimatedContainer>
        <StyledContainerGrid
          container
          wrap={'nowrap'}
          direction={'column'}
          spacing={spacing}
          className={'fadeIn'}
        >
          <Grid item>
            <Header />
          </Grid>
          {children}
        </StyledContainerGrid>
      </AnimatedContainer>
    </StyledLoginContainer>
  );
}

export default LoginRouteWrapper;
