import styled from '@emotion/styled';
import { Grid, FormControl, TextField, Fab, Snackbar } from '@ww/react-ui-components';
import { inputAutoFillStyles } from '/components/Login/Login.styled';

const { SingleSnackbar, SingleSnackbarContent } = Snackbar;

export const StyledContainerGrid = styled(Grid)<any>`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledFormControl = styled(FormControl)<any>`
  height: 75px;
  margin: 0 0 12px 0;
`;

export const StyledTextField = styled(TextField)`
  * {
    color: white;
  }
  ${inputAutoFillStyles}
  background-color: rgba(216, 216, 216, 0.2) !important;
`;

export const StyledSingleSnackbar = styled(SingleSnackbar)<any>`
  position: absolute;
  bottom: 24px !important;

  > div {
    box-shadow: none;
    color: #fb5755;
    background: rgba(250, 61, 59, 0.1);
    border: 1px solid #fa3d3b;
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledSingleSnackbarContent = styled(SingleSnackbarContent)<any>`
  padding: 0 15px;
  > div {
    width: 100% !important;
  }
`;

export const StyledDiv = styled('div')`
  min-height: 100px;
  position: relative;
`;

export const SignInLoader = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFab = styled(Fab)<any>`
  min-width: 170px !important;
  &:disabled {
    background-color: #d2dae1;
    cursor: not-allowed;
  }
`;

export const SignInFabContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const StyledForm = styled('form')`
  display: contents;
`;
