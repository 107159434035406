// @flow

import Compressor from 'compressorjs';

export function svgToDataUrl(svg: string) {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export function srcToImage(src: string, attrs: any = {}): Promise<window.HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = Object.assign(new Image(), attrs);
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.crossOrigin = 'Anonymous';
    image.src = src;
  });
}

export async function svgToPng(rawSVG: string) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const svgUrl = svgToDataUrl(rawSVG);
  const image = await srcToImage(svgUrl);
  image.width *= 1.5;
  image.height *= 1.5;
  const { width, height } = image;
  Object.assign(canvas, { width, height });
  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL();
}

export async function fetchAndCompressImage(imgUrl: string) {
  if (!imgUrl) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    fetch(imgUrl)
      .then(response => {
        if (response.status !== 200) {
          resolve(null);
        }

        return response.blob();
      })
      .then(img => {
        // eslint-disable-next-line no-new
        new Compressor(img, {
          quality: 0.2,
          convertSize: 0, // All png images above this size will be converted to jpg
          success(result) {
            resolve(URL.createObjectURL(result));
          },
        });
      })
      .catch(e => {
        reject(e);
      });
  });
}
