import React from 'react';
import { Grid, Icon } from '@ww/react-ui-components';

import styled from '@emotion/styled';
import config from '/configuration';

const StyledWindwardLogo = styled(Icon)`
  width: 100%;
  height: 80px;
`;

export default function Header() {
  return (
    <Grid container direction={'column'} alignItems={'center'} spacing={1}>
      <Grid item style={{ width: '100%' }}>
        <StyledWindwardLogo name={config.logoIcon} />
      </Grid>
    </Grid>
  );
}
