import BasicLoginUI from './BasicLogin';
import ProjectSelect from './ProjectSelect';
import SetNewPassword from './SetPassword';
import OAuthLogin from './OAuthLogin';

const ROUTES_NAMES = {
  LOGIN: '/',
  SET_PROJECT: '/set-project',
  SET_PASSWORD: '/set-password',
  AUTH_OIDC: '/auth/oidc/*',
};

export const AllRoutes = {
  [ROUTES_NAMES.LOGIN]: { path: ROUTES_NAMES.LOGIN, component: BasicLoginUI },
  [ROUTES_NAMES.SET_PROJECT]: { path: ROUTES_NAMES.SET_PROJECT, component: ProjectSelect },
  [ROUTES_NAMES.SET_PASSWORD]: { path: ROUTES_NAMES.SET_PASSWORD, component: SetNewPassword },
  [ROUTES_NAMES.AUTH_OIDC]: { path: ROUTES_NAMES.AUTH_OIDC, component: OAuthLogin },
};
