import React, { useContext, useEffect, useMemo } from 'react';

import { orderBy } from 'lodash';
import {
  AutocompleteProxy,
  Grid,
  TextField,
  WheelLoader,
  LoginContext,
} from '@ww/react-ui-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { organizations as organizationsQuery } from '/gql/queries/organization.queries';
import { selectProject as selectProjectQuery } from '/gql/mutations/user.mutations';
import { useAutoRedirect } from '/hooks/useAutoRedirect';
import { useNavigate } from 'react-router-dom';
import { StyledContainerGrid, StyledTypography, TextFieldWrapper } from './styled';
import PathContext from '/components/Login/PathContext';

export default function ProjectSelect() {
  const [getOrganizations, { loading, error, data }] = useLazyQuery(organizationsQuery);
  const {
    dispatch,
    state: { shouldSelectProject, token },
  } = useContext(LoginContext);
  const { prefix, useRefreshToken, authService } = useContext(PathContext);
  useAutoRedirect();
  const navigate = useNavigate();

  const [selectProject, { data: selectProjectResponse, loading: selectProjectLoading }] =
    useMutation(selectProjectQuery);

  const code = selectProjectResponse?.selectOrganization?.code;
  const tokens = selectProjectResponse?.selectOrganization?.tokens;
  const userData = selectProjectResponse?.selectOrganization?.user;

  useEffect(() => {
    if (!token) {
      navigate(prefix ?? '/');

      return;
    }

    getOrganizations();
  }, [token]);

  useEffect(() => {
    const { accessToken, refreshToken } = tokens ?? {};

    if (code || accessToken) {
      dispatch({ type: 'UPDATE_SHOULD_SELECT_PROJECT', shouldSelectProject: false });
    }

    if (accessToken) {
      dispatch({ type: 'UPDATE_TOKEN', token: accessToken });
    }

    if (refreshToken) {
      dispatch({ type: 'UPDATE_REFRESH_TOKEN', refreshToken });
    }
  }, [code, tokens]);

  useEffect(() => {
    if (userData) {
      dispatch({ type: 'UPDATE_USER_DATA', userData });
    }
  }, [userData]);

  useEffect(() => {
    if (code && !shouldSelectProject) {
      dispatch({ type: 'UPDATE_CODE', code });
    }
  }, [shouldSelectProject, code]);

  const organizations = useMemo(() => {
    if (!data?.organizations) {
      return [];
    }

    return orderBy(data.organizations, ['name'], ['asc']);
  }, [data?.organizations]);

  if (error) {
    /* eslint-disable-next-line */
    console.error('Error loading projects', error);

    return null;
  }

  const selectingProject = selectProjectLoading || Boolean(code);

  return (
    <StyledContainerGrid container justify={'center'} alignItems={'center'}>
      <Grid item xs container direction={'column'} spacing={4}>
        <Grid item>
          <StyledTypography variant={'h5'} fontWeight={200} align={'center'}>
            Select your project
          </StyledTypography>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteProxy
            css={{}}
            fullWidth
            loadingText={'Loading projects'}
            loading={loading}
            disabled={selectingProject}
            popupIcon={
              loading || selectingProject ? (
                <WheelLoader size={25} wwComponentName="LoadingProjectSelection" />
              ) : undefined
            }
            options={organizations}
            getOptionLabel={({ name }: any) => name}
            renderInput={params => (
              <TextFieldWrapper>
                <TextField {...params} placeholder={'Search for a project'} variant={'outlined'} />
              </TextFieldWrapper>
            )}
            onChange={(_, { id }: any) => {
              selectProject({
                variables: {
                  organizationId: id,
                  useRefreshToken,
                  useTemporaryCode: !authService,
                },
              });
            }}
          />
        </Grid>
      </Grid>
    </StyledContainerGrid>
  );
}
