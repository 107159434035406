import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Grid } from '@ww/react-ui-components';
import LoginRouteWrapper from '/components/Login/LoginRouteWrapper';
import { AllRoutes } from './Routes';

export function LoginRoutes() {
  return (
    <LoginRouteWrapper spacing={3}>
      <Grid item>
        <Routes>
          {Object.keys(AllRoutes).map(key => {
            const { path, component: Component } = AllRoutes[key];

            return <Route key={path} path={path} element={<Component />} />;
          })}
        </Routes>
      </Grid>
    </LoginRouteWrapper>
  );
}
