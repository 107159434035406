// @flow

import _ from 'lodash';
import ExtendableError from 'utils/ExtendableError';

type TGraphqlLocation = {
  line: number,
  column: number
};

type TGraphqlException = {
  status: number,
  response: Object,
  stacktrace: Array<string>
};

type TGraphqlExtensions = {
  code: string,
  exception: TGraphqlException
};

type TGraphqlErrorOutput = {
  message: string,
  locations: Array<TGraphqlLocation>,
  path: Array<string>,
  extensions: TGraphqlExtensions
};

export default class GraphqlError extends ExtendableError {
  errors: Array<Object> = [];
  constructor(message: string, errors: Array<TGraphqlErrorOutput>) {
    super(message);
    this.errors = errors;
  }
  get statusCodes(): Set<number> {
    const codes = this.errors.map(e => e.extensions.exception.status);
    return new Set(codes);
  }
  get errorsByStatus(): Map<string, Array<TGraphqlErrorOutput>> {
    const errorsByStatusObj = _.groupBy(this.errors, e =>
      _.get(e, 'extensions.exception.status')
    );
    // $FlowFixMe:: Flow doesn't understand the value of the map is always array (probably because group by doesnt typed)
    return new Map(Object.entries(errorsByStatusObj));
  }
}
