import styled from '@emotion/styled';
import { Grid, Typography } from '@ww/react-ui-components';
import { inputAutoFillStyles } from '/components/Login/Login.styled';

export const StyledContainerGrid = styled(Grid)<any>`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledTypography = styled(Typography)<any>`
  margin-top: 20px;
`;

export const TextFieldWrapper = styled.div`
  * {
    color: white;
  }
  ${inputAutoFillStyles}
  background-color: rgba(216, 216, 216, 0.2);
`;
