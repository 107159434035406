const { REACT_APP_DEPLOY_ENV } = process.env;

function getConfiguration() {
  if (['local', 'test'].includes(REACT_APP_DEPLOY_ENV!)) {
    return process.env;
  }

  const { ENV_CONFIG } = window as any;
  if (!ENV_CONFIG) {
    // eslint-disable-next-line no-console
    console.error('No ENV_CONFIG');
  }

  return ENV_CONFIG;
}

export default getConfiguration();
