import { gql } from '@apollo/client';

export const getIssuer = gql`
  query issuer($url: String!) {
    issuer(url: $url) {
      id
      url
      clientId
      createUserIfNotExists
      openidConfiguration
    }
  }
`;
