import { FormControl, Grid, TextField, FormHelperText } from '@ww/react-ui-components';
import React from 'react';
import styled from '@emotion/styled';
import InputEndIcon from './InputEndIcon';
import { inputAutoFillStyles } from '/components/Login/Login.styled';

const StyledFormControl = styled(FormControl)`
  height: 75px;
  margin: 0 0 12px 0;
`;

const StyledTextField = styled(TextField)`
  * {
    color: white;
  }
  ${inputAutoFillStyles}
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: white;
    }
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(0, 95, 204);
    }
  }
`;

const TextFieldWrapper = styled('div')`
  background-color: rgba(216, 216, 216, 0.2);
  border-radius: 4px;
`;

export default function PasswordField({
  wwComponentName,
  showPassword,
  placeholder,
  value,
  onChange,
  disabled,
  onClick,
  errors,
}: {
  wwComponentName: string;
  showPassword: boolean;
  disabled: boolean;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  errors: string[];
}) {
  const isErrors = errors.length > 0;

  return (
    <Grid item>
      <StyledFormControl>
        <TextFieldWrapper>
          <StyledTextField
            wwComponentName={wwComponentName}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            error={isErrors}
            InputProps={{
              endAdornment: <InputEndIcon onClick={onClick} showPassword={showPassword} />,
            }}
          />
        </TextFieldWrapper>
        {isErrors && (
          <FormHelperText error={isErrors} variant={'outlined'}>
            {errors[0]}
          </FormHelperText>
        )}
      </StyledFormControl>
    </Grid>
  );
}
