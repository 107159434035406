import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid, Typography, Fab } from '@ww/react-ui-components';

export const SetNewPasswordLoader = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const styledContainerFragment = css`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledAnnouncementContainer = styled(Grid)<any>`
  ${styledContainerFragment};
  margin-top: 20px;
`;

export const StyledPasswordsContainer = styled(Grid)<any>`
  margin-top: 20px;
`;

export const StyledContainerGrid = styled(Grid)<any>`
  ${styledContainerFragment};
`;

export const StyledFab = styled(Fab)<any>`
  min-width: 170px !important;
  &:disabled {
    background-color: #d2dae1;
    cursor: not-allowed;
  }
`;

export const SignInFabContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)<any>`
  margin-top: 20px;
  margin-bottom: 20px;
`;
