export const vesselProfileConstants = {
  AREA_TYPES: ['EEZ', 'OpenSea']
};

export const vesselStatus = {
  STATUS_DEAD: 'DEAD'
};

export const owners = [
  {
    key: 'beneficialOwners',
    sortedFieldName: 'sortedBeneficialOwners',
    currentFieldName: 'currentBeneficialOwner'
  },
  {
    key: 'owners',
    sortedFieldName: 'sortedOwners',
    currentFieldName: 'currentOwner'
  },
  {
    key: 'managers',
    sortedFieldName: 'sortedManagers',
    currentFieldName: 'currentManager'
  },
  {
    key: 'technicalManagers',
    sortedFieldName: 'sortedTechnicalManagers',
    currentFieldName: 'currentTechnicalManager'
  },
  {
    key: 'operators',
    sortedFieldName: 'sortedOperators',
    currentFieldName: 'currentOperator'
  },
  {
    key: 'commercialControllers',
    sortedFieldName: 'sortedCommercialControllers',
    currentFieldName: 'currentCommercialController'
  },
  {
    key: 'ismManagers',
    sortedFieldName: 'sortedIsmManagers',
    currentFieldName: 'currentIsmManager'
  }
];

export const infoBySection = {
  engine: [
    'engineBuilder',
    'engineCylinders',
    'engineStroke',
    'enginePower',
    'fuelConsumption',
    'propeller'
  ],
  dimensions: [
    'breadthExtreme',
    'breadthMoulded',
    'breadthRegistered',
    'depth',
    'draught',
    'height',
    'lengthBWPerpendiculars',
    'lengthOverall',
    'netTonnage',
    'displacementLightship',
    'displacementSummer',
    'freeboardSummer'
  ],
  speed: ['speedMax', 'speedService'],
  capacity: [
    'summerDwt',
    'grossTonnage',
    'netTonnage',
    'bale',
    'grain',
    'teu',
    'fuelType',
    'crudeCapacity'
  ],
  hull: [
    'largestHatch',
    'bulkheads',
    'decksNumber',
    'hullMaterial',
    'hullType',
    'longitudinalFrames'
  ],
  construction: [
    'builder',
    'build',
    'buildStart',
    'dateOfOrder',
    'deliveryDate',
    'keelLaid',
    'launchDate',
    'yardNumber'
  ]
};

export const VESSEL_PDF_EXPORT_FIELDS_CHANGE_EVENT =
  'vessel-pdf-export-fields-change';
