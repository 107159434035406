import ExtendableError from 'utils/ExtendableError';

type TGraphqlLocation = {
  line: number,
  column: number
};

type TGraphqlException = {
  status: number,
  response: Object,
  stacktrace: Array<string>
};

type TGraphqlExtensions = {
  code: string,
  exception: TGraphqlException
};

type TGraphqlErrorOutput = {
  message: string,
  locations: Array<TGraphqlLocation>,
  path: Array<string>,
  extensions: TGraphqlExtensions
};

export default class GraphQLClientError extends ExtendableError {
  constructor(errors: Array<TGraphqlErrorOutput>) {
    let errorMsg = `Error while making request to GQL`;

    if (Array.isArray(errors)) {
      const distinctErrors = new Set(errors.map(e => e.message));
      errorMsg += ` errors=[${[...distinctErrors.values()]}]`;
    }

    super(errorMsg);
    this.errors = errors;
  }

  hasErrorCode(errorCode: string): boolean {
    return this.errors.some(error => errorCode === error.extensions?.code);
  }
}
