export const OAUTH_ERRORS = {
  WRONG_ISSUER: {
    title: 'Login URL incorrect',
    message: 'Please check the login address or contact your client support representative.',
  },
  NO_ID_TOKEN: {
    title: 'Something went wrong',
    message: 'Go back to and try again', // Todo: Shira..
  },
  USER_NOT_FOUND: {
    title: "We can't find this user",
    message: "Please contact your organization's admin or your client support representative.",
  },
  LOGIN_FREQUENCY_LIMIT: {
    title: 'Your organization has reached its monthly limit of signed in users',
    message: "Contact your organization's admin or your Windward support rep for assistance.",
  },
};
