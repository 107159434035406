import { gql } from '@apollo/client';

export const organizations = gql`
  query organizations {
    organizations {
      name
      id
    }
  }
`;
