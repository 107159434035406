import React, { useContext, useEffect, useState, Dispatch, useCallback } from 'react';

import { Grid, Icon, Grow, ProgressBar, Typography, LoginContext } from '@ww/react-ui-components';
import * as userMutations from '/gql/mutations/user.mutations';
import { useMutation } from '@apollo/client';
import { useAutoRedirect } from '/hooks/useAutoRedirect';
import InputEndIcon from '/components/common/InputEndIcon';
import IconAnnouncement from '/components/common/IconAnnouncement';
import InsightLabLink from '/components/common/InsightLabLink';
import PathContext from '/components/Login/PathContext';

import {
  StyledContainerGrid,
  StyledForm,
  StyledDiv,
  StyledSingleSnackbar,
  StyledSingleSnackbarContent,
  StyledFormControl,
  StyledTextField,
  SignInFabContainer,
  StyledFab,
  SignInLoader,
} from './BasicLogin.styled';

export const LoginOutputResult = {
  SHOULD_SELECT_PROJECT: 'SHOULD_SELECT_PROJECT',
  SHOULD_SET_PASSWORD: 'SHOULD_SET_PASSWORD',
  SHOULD_FORCE_LOGIN: 'SHOULD_FORCE_LOGIN',
};

export const ErrorMessages = {
  GENERAL_ERROR: 'Problem Occurred',
  WRONG_CREDENTIALS: 'Wrong credentials',
};

export default function BasicLogin() {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [showPassword, setShowPassword]: [boolean, Dispatch<any>] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userLoggedInError, setUserLoggedInError] = useState(false);
  const { useRefreshToken, authService } = useContext(PathContext);
  const {
    state: { appName },
  } = useContext(LoginContext);

  useAutoRedirect();

  const [appLogin, { data: loginResponse, loading }] = useMutation(userMutations.login, {
    onError: error => {
      let { message } = error;
      if (![ErrorMessages.WRONG_CREDENTIALS].includes(message)) {
        message = ErrorMessages.GENERAL_ERROR;
      }

      setErrorMessage(message);
    },
  });
  const { dispatch } = useContext(LoginContext);

  useEffect(() => {
    if (loginResponse?.appLogin?.code) {
      dispatch({ type: 'UPDATE_CODE', code: loginResponse.appLogin.code });
    } else if (loginResponse && !loginResponse?.appLogin?.loginOutputResult) {
      setErrorMessage(ErrorMessages.GENERAL_ERROR);

      /* eslint-disable-next-line */
      console.error('Something Went Wrong, no code was given');
    }
  }, [loginResponse?.appLogin?.code]);

  useEffect(() => {
    const { accessToken } = loginResponse?.appLogin?.tokens ?? {};

    if (accessToken) {
      dispatch({ type: 'UPDATE_TOKEN', token: accessToken });
    }
  }, [loginResponse?.appLogin?.tokens?.accessToken]);

  useEffect(() => {
    const { refreshToken } = loginResponse?.appLogin?.tokens ?? {};

    if (refreshToken) {
      dispatch({ type: 'UPDATE_REFRESH_TOKEN', refreshToken });
    }
  }, [loginResponse?.appLogin?.tokens?.refreshToken]);

  useEffect(() => {
    const userPreferences = loginResponse?.appLogin?.userPreferences ?? {};

    if (userPreferences) {
      dispatch({ type: 'UPDATE_USER_PREFERENCES', userPreferences });
    }
  }, [loginResponse?.appLogin?.userPreferences]);

  useEffect(() => {
    const userData = loginResponse?.appLogin?.user ?? {};

    if (userData) {
      dispatch({ type: 'UPDATE_USER_DATA', userData });
    }
  }, [loginResponse?.appLogin?.user]);

  useEffect(() => {
    switch (loginResponse?.appLogin?.loginOutputResult) {
      case LoginOutputResult.SHOULD_SELECT_PROJECT:
        return dispatch({ type: 'UPDATE_SHOULD_SELECT_PROJECT', shouldSelectProject: true });
      case LoginOutputResult.SHOULD_SET_PASSWORD:
        return dispatch({ type: 'UPDATE_OTP', isOtp: true });
      case LoginOutputResult.SHOULD_FORCE_LOGIN:
        return setUserLoggedInError(true);
      default:
        break;
    }
  }, [loginResponse?.appLogin?.loginOutputResult]);

  const validateUsernamePassword = useCallback(() => {
    let errorMessage = '';

    if (!username && !password) {
      errorMessage = 'Please fill in your user name and password';
    } else if (!username) {
      errorMessage = 'Please fill in your user name';
    } else if (!password) {
      errorMessage = 'Please fill in your password';
    }

    setErrorMessage(errorMessage);

    return !errorMessage;
  }, [username, password, setErrorMessage]);

  const onSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();

      if (validateUsernamePassword()) {
        appLogin({
          variables: {
            username,
            password,
            application: appName,
            useRefreshToken,
            useTemporaryCode: !authService,
          },
        });
      }
    },
    [username, password]
  );

  const backToLogin = useCallback(() => setUserLoggedInError(false), []);

  const forceLogin = useCallback(
    () =>
      appLogin({
        variables: {
          username,
          password,
          forceLogin: true,
          application: appName,
          useRefreshToken,
          useTemporaryCode: !authService,
        },
      }),
    [username, password]
  );

  return (
    <StyledContainerGrid container justify={'center'} alignItems={'center'}>
      {userLoggedInError ? (
        <IconAnnouncement
          announcement={{
            title: 'Session in progress',
            message: (
              <>
                <Typography>This user is logged in on a different device.</Typography>
                <Typography>
                  Taking over the session will terminate the other session and any unsaved data may
                  be lost.
                </Typography>
              </>
            ),
          }}
          icon={'ErrorOutline'}
          actions={[
            { buttonCallback: backToLogin, buttonLabel: 'Back to login', color: 'primary' },
            {
              buttonCallback: forceLogin,
              buttonLabel: 'Log in anyway',
              loading,
              disabled: loading,
            },
          ]}
          wwComponentName={'sessionInProgress'}
        />
      ) : (
        <StyledForm onSubmit={onSubmit}>
          <Grid item container direction={'column'} alignItems={'stretch'} spacing={4}>
            <StyledDiv>
              <StyledSingleSnackbar
                open={Boolean(errorMessage)}
                onClose={() => setErrorMessage('')}
                TransitionComponent={props => <Grow {...props} />}
              >
                <StyledSingleSnackbarContent
                  message={
                    <Grid
                      container
                      justify={'center'}
                      alignItems={'center'}
                      spacing={1}
                      wrap={'nowrap'}
                    >
                      <Grid item>
                        <Icon name={'Error'} />
                      </Grid>
                      <Grid item>{errorMessage}</Grid>
                    </Grid>
                  }
                />
              </StyledSingleSnackbar>
            </StyledDiv>
            <Grid item xs>
              <StyledFormControl>
                <StyledTextField
                  wwComponentName="login-username"
                  variant="outlined"
                  placeholder="User Name"
                  value={username}
                  disabled={loading}
                  onChange={value => {
                    setErrorMessage('');
                    setUsername(value);
                  }}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledTextField
                  wwComponentName="login-password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  placeholder={'Password'}
                  value={password}
                  disabled={loading}
                  onChange={value => {
                    setErrorMessage('');
                    setPassword(value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputEndIcon onClick={setShowPassword} showPassword={showPassword} />
                    ),
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item container justify={'center'}>
              <Grid item xs={6} sm={4} lg={3}>
                <SignInFabContainer>
                  <StyledFab
                    size="large"
                    type="submit"
                    variant="extended"
                    color="secondary"
                    disabled={loading}
                  >
                    Sign In
                    {loading && (
                      <SignInLoader>
                        <ProgressBar size={24} color="secondary" />
                      </SignInLoader>
                    )}
                  </StyledFab>
                </SignInFabContainer>
              </Grid>
            </Grid>
          </Grid>
          <InsightLabLink appName={appName ?? ''} />
        </StyledForm>
      )}
    </StyledContainerGrid>
  );
}
