import React, { useContext, useState } from 'react';
import { generateVerifierAndGetChallenge, getRedirectUrl } from '/utils/oauth';
import { useUrlQuery } from '/utils/url';
import { WheelLoader, LoginContext } from '@ww/react-ui-components';

import IconAnnouncement, { Announcement } from '/components/common/IconAnnouncement';
import { OAUTH_ERRORS } from './constants';

type AuthProps = {
  issuerDetails: {
    issuer: string;
    clientID: string;
    clientSecret: string;
    authorizationURL: string;
    tokenURL: string;
    callbackURL: string;
    scope: string;
  };
};

export function LoginWithIdentityProviderRedirect({ issuerDetails }: AuthProps) {
  // Todo: remove me once integration done
  const debugOAuth = useUrlQuery().get('debug_oauth') ?? false;
  const [error, setError] = useState<Announcement | null>(null);
  const { state } = useContext(LoginContext);

  const { prevUrl } = state;

  let RedirectToIdProvider;

  if (issuerDetails && prevUrl) {
    const challenge = generateVerifierAndGetChallenge();
    RedirectToIdProvider = getRedirectUrl(issuerDetails, challenge, {
      prevUrl,
    });
  }

  if (!RedirectToIdProvider && !error) {
    setError(OAUTH_ERRORS.WRONG_ISSUER);
  } else if (!debugOAuth && !error) {
    window.location.replace(RedirectToIdProvider);

    return (
      <WheelLoader
        size={'large'}
        color={'inherit'}
        wwComponentName="LoginWithIdentityProviderRedirect.success"
      />
    );
  }

  return (
    <div>
      {error && (
        <IconAnnouncement
          icon={'ErrorOutline'}
          announcement={error}
          wwComponentName="LoginWithIdentityProviderRedirect.error"
        />
      )}
      {debugOAuth && ( // Todo: loose this once integration done
        <div>
          <h5>YOU ARE ONOAUTH</h5>
          <h4> HREF: {RedirectToIdProvider}</h4>
          {<a href={RedirectToIdProvider}>Thats where you click.</a>}
        </div>
      )}
    </div>
  );
}
