import { createContext } from 'react';
import AuthService from '@ww/react-utils/lib/services/auth.service';

const defaultValue = { prefix: '', basename: '', useRefreshToken: false };
const context = createContext<{
  prefix: string;
  basename?: string;
  useRefreshToken?: boolean;
  authService?: AuthService;
}>(defaultValue);

export default context;
